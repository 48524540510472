import { CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import CryptoJS from "crypto-js";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import storage from "../../../Utils/storage";
import arrow from "../../../assets/Arrow/ArrowRight.png";
import OfficeAddressIcon from "../../../assets/MyAccount/OfficeAddress.png";
import cross from "../../../assets/MyAccount/cross_button.png";
import HomeAddressIcon from "../../../assets/MyAccount/homeAddress.png";
import myAccountDesign from "../../../assets/MyAccount/myAccountDesign.png";
import greenTick from "../../../assets/cart/greenTick.png";
import Coupon from "../../../assets/coupon.png";
import finalPaymentPlanDescriptionCardImage from "../../../assets/payment/finalPaymentPlanDescriptionCardImage.png";
import rightArrow from "../../../assets/rightArrow.png";
import { createOrderRequest } from "../../../redux/Action/Cart/CreateOrderAction";
import { GetAllCouponRequest } from "../../../redux/Action/Cart/GetAllCouponAction";
import { paymentGatewayRedirectRequest } from "../../../redux/Action/Cart/PaymentGatewayRedirectRequest";
import { getPlanFullDetailsRequest } from "../../../redux/Action/Plan/GetPlanFullDetailsAction";
import { refreshTokenRequest } from "../../../redux/Action/RefreshToken/refreshTokenAction";
import YuvaLoader from "../../Atoms/Loader/YuvaLoader";
import Title from "../../Atoms/Title/Title";
import "./FinalPaymentPageNewStyle.css";
import ZeroPaymentConfirmation from "./ZeroPaymentConfirmation";
import { Helmet } from "react-helmet";
import ScrollToTop from "../../Molecules/ScrollToTop/ScrollToTop";

function FinalPaymentPlan() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uuid = storage.getStorage("PurchasePlanUuid");
  const [appliedCoupon, SetAppliedCoupon] = React.useState("");
  const getPlanFullDetails = useSelector(
    (state) => state.plan.getPlanFullDetails
  );
  const [selectedOption, setSelectedOption] = React.useState();
  const [coupon, setCoupon] = useState("");
  const [coupon1, setCoupon1] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPin, setNewPin] = useState();
  const [couponCode, setCouponCode] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [cod, setIsCod] = useState("false");
  const [amountToBePaid, setAmountToBePaid] = useState("Amount to be paid");
  const [paymentLinkGenerated, setPaymentLinkGenerated] = useState("");
  const [isPaymentLink, setIsPaymentLink] = useState("false");
  const [showPopup, setShowPopup] = useState(false);
  const refreshToken = useSelector((state) => state.refreshToken.refreshToken);
  const getAllCoupons = useSelector((state) => state.cart.getAllCoupon);
  const getPlanDetail = useSelector((state) => state.plan.getPlanDetail);
  const getCart = useSelector((state) => state.cart.getCart);
  const createOrder = useSelector((state) => state.cart.createOrder);
  const paymentGatewayRedirect = useSelector(
    (state) => state.cart.paymentGatewayRedirect
  );

  const paymentURL = process.env.REACT_APP_CCAVENUE_URL;
  const paymentAccessCode = process.env.REACT_APP_ACCESS_CODE;
  const secretKey = process.env.REACT_APP_SECURITY_KEY;
  const [confirmPaymentFlag, setConfirmPaymentFlag] = useState(false);

  useEffect(() => {
    let dispatchObject = { uuid, appliedCoupon, selectedOption };
    if (uuid !== "undefined") {
      dispatch(getPlanFullDetailsRequest(dispatchObject));
    }
  }, [uuid, appliedCoupon]);

  function handlePaymentMethod(e,paymentLink) {
    setIsCod(e);    
    setIsPaymentLink(paymentLink)
  }
  useEffect(() => {
    if (selectedOption !== undefined) {
      let dispatchData = {
        uuid: uuid,
        enumType: "plan",
        selectedOption: selectedOption,
      };

      dispatch(GetAllCouponRequest(dispatchData));
    }
  }, [selectedOption]);

  useEffect(() => {
    if (getPlanFullDetails?.data?.data?.data?.data.couponCode !== "") {
      setCouponCode(getPlanFullDetails?.data?.data?.data?.data.couponCode);
    }
  }, [getPlanFullDetails]);

  useEffect(() => {
    if (createOrder.isLoading) {
      setShowLoader(true);
      return;
    }
    if (
      createOrder.data?.data.status === 200 &&
      createOrder.data?.data.data.data.amountZero === false
    ) {
      let sd = {
        id: createOrder.data?.data.data.data.id,
        plan: true,
      };
      if (cod === "false") {
        dispatch(paymentGatewayRedirectRequest(sd));
      } else {
        setConfirmPaymentFlag(true);
      }
    }
    if (
      createOrder.data?.data.status === 200 &&
      createOrder.data?.data.data.data.amountZero === true
    ) {
      window.location.href = "./ConfirmedZeroPayment";
    }
    if (
      createOrder?.error?.data?.response?.status === 404 ||
      createOrder?.error?.data?.response?.status
    ) {
      Swal.fire({
        title: `${createOrder.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [createOrder]);
  const determinePaymentMode = (paymentLink, cod) => {
    if (paymentLink === "false" && cod === "false") {
        return "ONLINE";
    } else if (paymentLink === "true" && cod === "false") {
        return "PAYMENT_LINK";
    } else if (paymentLink === "false" && cod === "true") {
        return "COD";
    }
};

  useEffect(() => {
    if (refreshToken.data !== null) {
      const encryptedToken = CryptoJS.AES.encrypt(
        refreshToken.data?.data.data.data.refreshToken,
        secretKey
      ).toString();
      storage.setStorage("token", refreshToken.data?.data.data.data.jwt);
      storage.setStorage("sessionToken", encryptedToken);
      window.dispatchEvent(new Event("refresh"));
      let subscriptionRequestDto = {
        address: location.state.address,
        cityId: location.state.cityId,
        couponName: appliedCoupon === "" ? null : appliedCoupon,
        name: location.state.contactName,
        number: location.state.contactNumber,
        pinCode: location.state.pinCode,
        planTypeEnum: selectedOption,
        planUuid: uuid,
      };
      if (getCart?.data?.data?.data?.data?.totalCost === 0) {
        var finalState = { subscriptionRequestDto, cod: "false" };
      } else {
        var finalState = {
          subscriptionRequestDto,
          cod: cod,
          paymentMode: determinePaymentMode(isPaymentLink, cod),
        };
      }
      dispatch(createOrderRequest(finalState));
    } else if (refreshToken.error !== null) {
      navigate("/login");
    }
  }, [refreshToken]);

  useEffect(() => {
    if (paymentGatewayRedirect.data?.data.status === 200) {
      if(isPaymentLink === "true" && cod === "false"){
         const paymentLink = `${paymentURL}?command=initiateTransaction&encRequest=${paymentGatewayRedirect.data?.data.data.data.encRequest}&access_code=${paymentAccessCode}`;

         navigator.clipboard
           .writeText(paymentLink)
           .then(() => {
            setAmountToBePaid("Copy Payment Link");
            setPaymentLinkGenerated(paymentLink);
            setShowPopup(true);

            // Hide the popup after 2 seconds
            setTimeout(() => {
              setShowPopup(false);
            }, 2000);
           })
           .catch((error) => {
             console.error("Failed to copy payment link: ", error);
           });

      }else{
      window.location.href = `${paymentURL}?command=initiateTransaction&encRequest=${paymentGatewayRedirect.data?.data.data.data.encRequest}&access_code=${paymentAccessCode}`;
      }      
      setShowLoader(false);
    }
  }, [paymentGatewayRedirect]);

  if (confirmPaymentFlag) {
    navigate("/confirmPayment", {
      state: { confirmPaymentFlag: confirmPaymentFlag },
    });
  }

  useEffect(() => {
    if (
      getPlanFullDetails?.data?.data?.data?.data.planAmountResponse !==
      undefined
    ) {
      setSelectedOption(
        Object.keys(
          getPlanFullDetails?.data?.data?.data?.data.planAmountResponse
        ).find(
          (key) =>
            getPlanFullDetails?.data?.data?.data?.data.planAmountResponse[key]
              .show
        )
      );
    }
  }, [getPlanFullDetails?.data]);

  useEffect(() => {
    if (
      getPlanFullDetails?.error?.data?.response?.status === 400 &&
      getPlanFullDetails?.error?.data?.response?.data.errorMessage !== ""
    ) {
      Swal.fire({
        title: `${getPlanFullDetails?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
    if (
      getPlanFullDetails?.error?.data?.response?.status === 404 &&
      getPlanFullDetails?.error?.data?.response?.data.errorMessage !== ""
    ) {
      Swal.fire({
        title: `${getPlanFullDetails?.error?.data?.response?.data.errorMessage}`,
        icon: "error",
      }).then(() => {
        window.location.reload();
      });
    }
  }, [getPlanFullDetails]);

  const handlePayment = () => {
    if(amountToBePaid === "Amount to be paid"){
    if (isChecked !== false) {
      const fcmToken = storage.getStorage("fcmToken");
      const token = storage.getStorage("sessionToken");
      const secretKey = process.env.REACT_APP_SECURITY_KEY;
      const decryptedBytes = CryptoJS.AES.decrypt(token, secretKey);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      dispatch(refreshTokenRequest(decryptedData, fcmToken));
    } else {
      Swal.fire({
        text: "Please Agree to our Terms and Conditions & Privacy Policy",
        icon: "error",
      });
    }
  }else if(amountToBePaid === "Copy Payment Link"){
    navigator.clipboard
      .writeText(paymentLinkGenerated)
      .then(() => {
        setShowPopup(true);

        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
         
      })
      .catch((error) => {
        console.error("Failed to copy payment link: ", error);
      });
  }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleApplyCoupon = (event) => {
    SetAppliedCoupon(event.target.attributes.code.value.toUpperCase());
    setNewPin(event.target.attributes.pin.value);
  };

  const handleApply = () => {
    setCoupon(coupon1);
    if (coupon1 === "") {
      setErrorMessage("Please enter the coupon code");
    }
  };

  useEffect(() => {
    if (coupon !== "") {
      SetAppliedCoupon(coupon);
      setErrorMessage("");
    }
  }, [coupon]);

  const handleCoupon = (e) => {
    setCoupon1(e.target.value.toUpperCase());
  };

  const RemoveCoupon = () => {
    setCoupon1("");

    SetAppliedCoupon("");
    window.location.reload();
  };
  
  if (getPlanFullDetails.isLoading || getPlanFullDetails.data === null) {
    return <YuvaLoader />;
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  if (location.state === null || storage.getStorage("token") === null) {
    navigate("/");
  } else {
    return (
      <>
        <Helmet>
          <title>YUVA | Final Payment</title>
        </Helmet>
        <ScrollToTop />
        {createOrder.data?.data.status === 200 &&
        createOrder.data?.data.data.data.amountZero === true ? (
          <ZeroPaymentConfirmation />
        ) : (
          <div
            className="finalPaymentNewPageWrapper"
            style={{
              backgroundImage: `url(${myAccountDesign})`,
              backgroundSize: "100% 39%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="finalPaymentNewPageMain">
              <div className="finalPaymentNewPageLeftPartWrapper">
                <div className="finalPaymentNewPageHeader">
                  <h1 className="finalPaymentNewPageHeaderText">Payment</h1>
                </div>
                <div className="finalPaymentNewPageAddressblock">
                  <div className="finalPaymentNewColumn">
                    <div className="finalPaymentNewAddressName">
                      {location.state.address}
                    </div>
                    {location.state.away ? (
                      <img
                        src={OfficeAddressIcon}
                        className="imgPaymentNew"
                        alt="Office bag"
                      />
                    ) : (
                      <img
                        src={HomeAddressIcon}
                        className="imgPaymentNew"
                        alt="Home"
                      />
                    )}
                  </div>
                  <div className="finalPaymentNewAddress">
                    {location.state.cityName},{location.state.pinCode}
                  </div>
                  <div className="finalPaymentNewAddress">
                    {" "}
                    {location.state.contactNumber}
                  </div>
                </div>

                <div className="CartMainSecondContentCouponNew">
                  <div className="couponHeaderNew">Available Coupons</div>
                  <div className="CouponsAreaNewPlan">
                    {getAllCoupons?.data?.action?.data?.data.totalCoupons !==
                    0 ? (
                      getAllCoupons?.data?.action?.data?.data.userCouponResponseDtoList.map(
                        (item, index) => (
                          <div className="CartCardContentNewplans">
                            {item.maxDiscount === null ? (
                              <p className="CartCardContentChild1plan">
                                {item.discountAmountOrPercentage}/- OFF
                              </p>
                            ) : (
                              <p className="CartCardContentChild1plan">
                                ₹ {item.discountAmountOrPercentage}%- OFF upto ₹
                                {item.maxDiscount}
                              </p>
                            )}

                            <p className="CartCardContentChild2plan">
                              Code: {item.couponCode}
                            </p>
                            <div className="CartCardContentChild3Plan">
                              <div className="ApplyCodeplan">
                                {parseInt(newPin) === index ||
                                couponCode === item.couponCode ? (
                                  <img src={greenTick} className="couponIcon" />
                                ) : (
                                  <img
                                    src={rightArrow}
                                    onClick={handleApplyCoupon}
                                    code={item.couponCode}
                                    pin={index}
                                    alt="right arrow"
                                    className="couponIcon"
                                  />
                                )}
                                <p
                                  onClick={handleApplyCoupon}
                                  code={item.couponCode}
                                  pin={index}
                                >
                                  {parseInt(newPin) === index ||
                                  couponCode === item.couponCode ? (
                                    <p className="appliedPara">Applied</p>
                                  ) : (
                                    "Apply"
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className="NoCouponAvaibleTokenPlan">
                        {" "}
                        No Coupon Available{" "}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="finalPaymentNewRightPartWrapper">
                {getPlanFullDetails?.data?.data?.data?.data !== undefined && (
                  <div className="finalPaymentPlanNewDescription">
                    <div className="finalPaymentPlanNewDescriptionCard">
                      <div className="finalPaymentPlanNewDescriptionCardpart1">
                        <img
                          src={finalPaymentPlanDescriptionCardImage}
                          className="finalPaymentPlanNewDescriptionCardImage"
                          alt="3 doctors"
                        />
                        <div className="finalPaymentPlanNewDescriptionCardpart1sub2">
                          <div className="finalPaymentPlanNewDescriptionCardpart1Heading">
                            <h1 className="finalPaymentPlanNewDescriptionHeader">
                              {getPlanFullDetails?.data?.data?.data?.data.name}
                            </h1>
                          </div>
                          <h1 className="finalPaymentPlanNewDescriptionQuantity">
                            Quatity : 1
                          </h1>
                        </div>
                      </div>
                      <div className="finalPaymentPlanNewDescriptionCardpart1Right">
                        <select
                          className="finalPaymentPlanNewDropdown"
                          value={selectedOption}
                          onChange={(e) => handleOptionChange(e)}
                        >
                          {getPlanFullDetails?.data?.data?.data?.data.planType.map(
                            (item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            )
                          )}
                        </select>
                        <h1 className="finalPaymentPlanNewDescriptionHeaderPrice">
                          ₹{" "}
                          {
                            getPlanFullDetails?.data?.data?.data?.data
                              ?.planAmountResponse[selectedOption]?.price
                          }
                        </h1>
                      </div>
                    </div>

                    <hr className="finalPaymentPlanNewDescriptionLineSeperator" />

                    <div className="finalPaymentPlanNewDescriptionP1">
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        Discount
                      </h1>

                      <h1 className="finalPaymentPlanNewDescriptionFixedText4">
                        - ₹{" "}
                        {
                          getPlanFullDetails?.data?.data?.data?.data
                            ?.planAmountResponse[selectedOption]
                            ?.discountBeforeCoupon
                        }
                      </h1>
                    </div>

                    <div className="finalPaymentPlanNewDescriptionP1">
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        Order Amount
                      </h1>
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        ₹{" "}
                        {
                          getPlanFullDetails?.data?.data?.data?.data
                            ?.planAmountResponse[selectedOption]
                            ?.costAfterDiscount
                        }
                      </h1>
                    </div>

                    <hr className="finalPaymentPlanNewDescriptionLineSeperator" />

                    <div>
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        Coupon
                      </h1>
                      <div className="CodeAlertNew">
                        <div className="CodeAlertNewInput">
                          <input
                            className="CouponCodeCartNew"
                            placeholder="Coupon Code"
                            name="Coupon Code"
                            style={{ textTransform: "uppercase" }}
                            id="CouponCodeCartId"
                            value={coupon1}
                            onChange={handleCoupon}
                          />
                        </div>
                        <div
                          className="CouponApplyButtonNew"
                          onClick={handleApply}
                        >
                          <img src={arrow} alt="arrow" />
                        </div>
                      </div>
                      <p className="errorMessageCart">{errorMessage}</p>
                      {appliedCoupon !== "" && (
                        <div className="finalPaymentPlanNewDescriptionP1New">
                          <div className="finalPaymentPlanNewAppliedCoupon">
                            <img src={Coupon} alt="CouponIcon" />

                            <p>{appliedCoupon}</p>
                            <div className="AppliedcouponNewRight">
                              <img
                                src={cross}
                                onClick={RemoveCoupon}
                                alt="crossIcon"
                              />
                            </div>
                          </div>
                          <h1 className="finalPaymentPlanNewDescriptionFixedText4">
                            - ₹{" "}
                            {
                              getPlanFullDetails?.data?.data?.data?.data
                                .planAmountResponse[selectedOption]
                                .discountForCoupon
                            }
                          </h1>
                        </div>
                      )}
                    </div>

                    <hr className="finalPaymentPlanNewDescriptionLineSeperator" />

                    <div className="finalPaymentPlanNewDescriptionP1">
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        Amount Payable
                      </h1>
                      <h1 className="finalPaymentPlanNewDescriptionFixedText2">
                        ₹{" "}
                        {
                          getPlanFullDetails?.data?.data?.data?.data
                            ?.planAmountResponse[selectedOption]?.amountToBePaid
                        }
                      </h1>
                    </div>
                    <div>
                      <div
                        className="AddressListLowerDateAndTime"
                        onClick={() => handlePaymentMethod("true", "false")}
                      >
                        <div className="SelectPaymemtLeft">
                          <input
                            type="radio"
                            value={"true"}
                            id="cod"
                            name="paymentMode"
                            checked={
                              cod === "true" && isPaymentLink === "false"
                            }
                            onChange={() =>
                              handlePaymentMethod("true", "false")
                            }
                          />
                          <p className="SelectPaymemtLeftLabel">Cash</p>
                        </div>
                        <p className="SelectPaymentTerms">
                          Term & Condition Apply*
                        </p>
                      </div>
                      <div
                        className="AddressListLowerDateAndTime"
                        onClick={() => handlePaymentMethod("false", "false")}
                      >
                        <div className="SelectPaymemtLeft">
                          <input
                            type="radio"
                            value={"false"}
                            id="online"
                            name="paymentMode"
                            checked={
                              cod === "false" && isPaymentLink === "false"
                            }
                            onChange={() =>
                              handlePaymentMethod("false", "false")
                            }
                          />
                          <p className="SelectPaymemtLeftLabel">Online</p>
                        </div>
                        <p className="SelectPaymentTerms">
                          Term & Condition Apply*
                        </p>
                      </div>
                      <div
                        className="AddressListLowerDateAndTime"
                        onClick={() => handlePaymentMethod("false", "true")}
                      >
                        <div className="SelectPaymemtLeft">
                          <input
                            type="radio"
                            value={"false"}
                            id="paymentLink"
                            name="paymentMode"
                            checked={
                              cod === "false" && isPaymentLink === "true"
                            }
                            onChange={() =>
                              handlePaymentMethod("false", "true")
                            }
                          />
                          <p className="SelectPaymemtLeftLabel">Payment Link</p>
                        </div>
                        <p className="SelectPaymentTerms">
                          Term & Condition Apply*
                        </p>
                      </div>
                    </div>
                    <div className="finalPaymentPlanNewDescriptionMarketing">
                      <Checkbox
                        defaultChecked
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="PlanCheckBox"
                      />
                      <p className="finalPaymentPlanDescriptionMarketingText">
                        By clicking on the below button, you agree to our{" "}
                        <a href="/terms-and-conditions">Terms and Conditions</a>{" "}
                        & <a href="/privacy-policy">Privacy Policy</a>{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="buttonBlock" style={{ position: 'relative' }}>
              {showPopup && (
                <div
                  style={{
                    position: "absolute",
                    top: "-40px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    backgroundColor: "#f5f5dc", // Cream white
                    border: "1px solid black",
                    color: "black",
                    borderRadius: "8px",
                    padding: "5px 10px",
                    fontSize: "12px",
                    zIndex: 10,
                  }}
                >
                  Copied!
                </div>
              )}
              {getPlanFullDetails?.data?.data?.data?.data !== undefined && (
                <button
                  onClick={handlePayment}
                  className="finalButtonPaymentCart"
                  style={showLoader ? { className: "finalButtonPayment" } : {}}
                  disabled={showLoader ? true : false}
                >
                  {showLoader ? (
                    <CircularProgress sx={{ color: "#fff" }} />
                  ) : (
                    <div>
                      {amountToBePaid}
                      <br /> ₹{" "}
                      {
                        getPlanFullDetails?.data?.data?.data?.data
                          ?.planAmountResponse[selectedOption]?.amountToBePaid
                      }
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
export default FinalPaymentPlan;
